import React, { useEffect, useState } from "react";
import { Menu, Dropdown, Avatar, Form } from "antd";
import { connect, useSelector } from "react-redux";
import { LogoutOutlined, EditOutlined, LockOutlined } from "@ant-design/icons";
import Icon from "components/util-components/Icon";
import { signOut } from "redux/actions/Auth";
import { useHistory } from "react-router-dom";
import { UserLogout } from "redux/actions/logout/index";
import { useDispatch } from "react-redux";
import NavProfileModal from "./modal/NavProfileModal";
import ChangePassModal from "./modal/ChangePassModal";
import { GetAdminById } from "redux/actions/admin/adminById.action";
import { isValidObject } from "utils/a1CardUtils";
import Cookies from "js-cookie";

export const NavProfile = ({ signOut }) => {
  let dispatch = useDispatch();
  let history = useHistory();

  // let userId = localStorage.getItem("user_id");
  let userId = Cookies.get("user_id");

  const [form] = Form.useForm();

  const getAdminByIdReducer = useSelector((state) => state.getAdminByIdReducer);

  const [openEditModal, setOpenEditModal] = useState(false);
  const [openChangePasswordModal, setOpenChangePasswordModal] = useState(false);
  const [adminData, setAdminData] = useState([]);
  const [thumbnail, setThumbnail] = useState();
  const [editAdmin, setEditAdmin] = useState();

  useEffect(() => {
    dispatch(GetAdminById(userId)).then(() => {
    })
  }, [dispatch, userId])

  useEffect(() => {
    const { data } = getAdminByIdReducer;
    if (isValidObject(data)) {
      setAdminData(data);
    }
  }, [getAdminByIdReducer]);

  const modalToggle = () => {

    setEditAdmin({
      name: adminData[0]?.name,
    })

    setOpenEditModal(!openEditModal)
    form.resetFields();
  }



  const changePassModalToggle = () => {
    setOpenChangePasswordModal(!openChangePasswordModal);
    form.resetFields();
  }


  // const profileImg = adminData[0]?.profile_pic;

  const UserLogOut = () => {
    dispatch(UserLogout(userId)).then(() => {
      Cookies.remove("user_id");
      Cookies.remove("token");
      history.push("/auth/login");
    });
  };

  const profileMenu = (
    <div className="nav-profile nav-dropdown">
      <div className="nav-profile-header">
        <div className="d-flex align-items-center">
          <Avatar shape="square" size={42} src={thumbnail && thumbnail !== undefined ? thumbnail : adminData[0]?.profile_pic} />
          <div className="pl-3">
            <h4 className="mb-0">{adminData[0]?.name}</h4>
            <span className="text-muted">Admin</span>
          </div>
        </div>
      </div>
      <div className="nav-profile-body">
        <Menu>
          <Menu.Item>
            <span onClick={modalToggle} >
              <Icon className="mr-3" type={EditOutlined} />
              <span className="font-weight-normal">Edit Profile</span>
            </span>
          </Menu.Item>

          <Menu.Item>
            <span onClick={changePassModalToggle} >
              <Icon className="mr-3" type={LockOutlined} />
              <span className="font-weight-normal">Change Password</span>
            </span>
          </Menu.Item>

          <Menu.Item onClick={UserLogOut}>
            <span>
              <LogoutOutlined className="mr-3" />
              <span className="font-weight-normal">Sign Out</span>
            </span>
          </Menu.Item>
        </Menu>
      </div>
    </div>
  );
  return (
    <>
      <Dropdown placement="bottomRight" overlay={profileMenu} trigger={["click"]}>
        <Menu className="d-flex align-item-center" mode="horizontal">
          <Menu.Item key="profile">
            {/* <img src={profileImg} /> */}
            <Avatar shape="square" size={32} src={thumbnail && thumbnail !== undefined ? thumbnail : adminData[0]?.profile_pic} />
          </Menu.Item>
        </Menu>
      </Dropdown>

      {/*  Modal */}

      <NavProfileModal setThumbnail={setThumbnail} editAdmin={editAdmin} modalToggle={modalToggle} openEditModal={openEditModal} form={form} userId={userId} />

      <ChangePassModal changePassModalToggle={changePassModalToggle} openChangePasswordModal={openChangePasswordModal} form={form} userId={userId} />
    </>
  );
};

export default connect(null, { signOut })(NavProfile);
