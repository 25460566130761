import moment from "moment";
import axios from "axios";
import * as Dateformat from "../constants/DateConstant";
import Cookies from "js-cookie";

export const isValidArray = (arr) => {
    return arr && Array.isArray(arr) && arr.length > 0;
};

export const isValidObject = (obj) => {
    return obj && Object.keys(obj).length > 0;
};

export const getQueryParams = (location, value) => {
    const params = new URLSearchParams(location.search);
    return params.get(value);
};

export const getUserId = () => {
    // return localStorage.getItem("user_id");
    return Cookies.get("user_id");
};

export const getUserToken = () => {
    // return localStorage.getItem("token");
    return Cookies.get("token");
};

export const isValidToken = (token) => {
    // return localStorage.getItem("token") === token;
    return Cookies.get("token") === token;
};

export const setUrl = () => {
    const devUrl = process.env.REACT_APP_API;
    const prodUrl = process.env.REACT_APP_API;
    return process.env.NODE_ENV === "development" ? devUrl : prodUrl;
};

export const formatDate = (date) => {
    const formatDate = moment.utc(date).add(12, "hours").format(Dateformat.NYT);
    return formatDate;
};

export const downloadQR = (urls) => {
    axios({
        method: "get",
        url: urls,
        responseType: "arraybuffer",
    })
        .then((response) => {
            var link = document.createElement("a");
            link.href = window.URL.createObjectURL(
                new Blob([response.data], { type: "application/octet-stream" })
            );
            link.download = urls.split("/")[4];

            document.body.appendChild(link);

            link.click();
            setTimeout(function () {
                window.URL.revokeObjectURL(link);
            }, 200);
        })
        .catch((error) => { });
};

export const getFileType = (type) => {
    if (type.startsWith('video/')) {
        return 'video';
    }
    if (type.startsWith('image/')) {
        return 'image';
    }
    return type;
};

export const fileToBase64 = (e, eventPath = 'target') => {
    return new Promise((resolve, reject) => {
        if (e && e[eventPath] && e[eventPath].files && e[eventPath].files[0]) {
            const file = e[eventPath].files[0];
            const reader = new FileReader();

            // Read file content on file loaded event
            reader.onload = function (event) {
                resolve({
                    file: event.target.result,
                    type: getFileType(file.type),
                    name: file.name,
                });
            };

            // Convert data to base64
            reader.readAsDataURL(file);
        } else {
            reject('Something went wrong.');
        }
    });
};

export const filterByDate = (date, dateString, data, type = null) => {
    
    let temp = [];
    
    if (dateString[0] === "" && dateString[1] === "") {
        return data;
    } else if (type == "cardReport") {
        for (let item of data) {
            let date = moment(item.created_date).add(12,"hours").format("YYYY-MM-DD")
            if (moment(date).isBetween(dateString[0], dateString[1], undefined, [])) {
                temp.push(item);
            }
        }
        return temp
    }
    else {
        // let temp = [];
        for (let item of data) {
            let date = moment(item.created_date).format("YYYY-MM-DD")
            if (moment(date).isBetween(dateString[0], dateString[1], undefined, [])) {
                temp.push(item);
            }
        }
        return temp
    }
}