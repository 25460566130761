import { ORDER_STATUS_UPDATE_SUCCESS, ORDER_STATUS_UPDATE_FAILURE } from "../../constants/index";
import { onSuccess, onFailure } from "../common";

const initStat = { data: [], error: false, errorData: "", isLoading: true };

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initStat, action) => {
    switch (action.type) {
        case ORDER_STATUS_UPDATE_SUCCESS:
            return onSuccess(state, action);
        case ORDER_STATUS_UPDATE_FAILURE:
            return onFailure(state, action);
        default:
            return { ...state };
    }
};
