import {
  DashboardOutlined,
  GiftOutlined,
  UserOutlined,
  IdcardOutlined,
  BookOutlined,
  UserSwitchOutlined,
  CreditCardOutlined,
  CommentOutlined,
  RetweetOutlined,
  TeamOutlined,
  FileDoneOutlined,
  SafetyOutlined,
  SolutionOutlined,
  DatabaseOutlined,
  ShopOutlined,
} from "@ant-design/icons";
import { APP_PREFIX_PATH } from "configs/AppConfig";

const dashBoardNavTree = [
  {
    key: "dashboards",
    path: `${APP_PREFIX_PATH}/dashboards`,
    title: "Navigation",
    icon: DashboardOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: "home",
        path: `${APP_PREFIX_PATH}/home`,
        title: "Dashboard",
        icon: DashboardOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "order",
        title: "Orders",
        icon: GiftOutlined,
        breadcrumb: false,
        submenu: [
          {
            key: "order",
            path: `${APP_PREFIX_PATH}/orders`,
            title: "Orders",
            icon: GiftOutlined,
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "replacement",
            path: `${APP_PREFIX_PATH}/replacement`,
            title: "Replacement",
            icon: RetweetOutlined,
            breadcrumb: false,
            submenu: [],
          },
        ],
      },
      {
        key: "users",
        title: "Users",
        icon: UserOutlined,
        breadcrumb: false,
        submenu: [
          {
            key: "user",
            path: `${APP_PREFIX_PATH}/user`,
            title: "Users",
            icon: UserOutlined,
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "organizations",
            path: `${APP_PREFIX_PATH}/organization`,
            title: "Organization",
            icon: TeamOutlined,
            breadcrumb: false,
            submenu: [],
          },
        ],
      },

      {
        key: "master",
        title: "Master",
        icon: DatabaseOutlined,
        breadcrumb: false,
        submenu: [
          {
            key: "products",
            path: `${APP_PREFIX_PATH}/products_category`,
            title: "Product Category",
            icon: ShopOutlined,
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "subproducts",
            path: `${APP_PREFIX_PATH}/productsubcategory`,
            title: "Sub Product Category",
            icon: ShopOutlined,
            breadcrumb: false,
            submenu: [],
          },
        ],
      },
      {
        key: "inventory",
        // path: `${APP_PREFIX_PATH}/card`,
        title: "Inventory",
        icon: FileDoneOutlined,
        breadcrumb: false,
        submenu: [
          {
            key: "card",
            path: `${APP_PREFIX_PATH}/card`,
            title: "Cards",
            icon: CreditCardOutlined,
            breadcrumb: false,
            submenu: [],
          },

        ],
      },
      {

        key: "venders",
        path: `${APP_PREFIX_PATH}/venders`,
        title: "Vendors",
        icon: SolutionOutlined,
        breadcrumb: false,
        submenu: [],

      },
      {

        key: "insurance",
        path: `${APP_PREFIX_PATH}/insurance`,
        title: "Insurance",
        icon: SafetyOutlined,
        breadcrumb: false,
        submenu: [],

      },
      {
        key: "report",
        // path: `${APP_PREFIX_PATH}/card`,
        title: "Report",
        icon: BookOutlined,
        breadcrumb: false,
        submenu: [
          {
            key: "user_report",
            path: `${APP_PREFIX_PATH}/userreport`,
            title: "User Report",
            icon: UserSwitchOutlined,
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "card_report",
            path: `${APP_PREFIX_PATH}/cardreport`,
            title: "Card Report",
            icon: IdcardOutlined,
            breadcrumb: false,
            submenu: [],
          },
        ],
      },
      {
        key: "leads",
        path: `${APP_PREFIX_PATH}/leads`,
        title: "Leads",
        icon: CommentOutlined,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
];

const navigationConfig = [...dashBoardNavTree];

export default navigationConfig;
