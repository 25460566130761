import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from "components/shared-components/Loading";
import { APP_PREFIX_PATH } from "configs/AppConfig";

export const AppViews = () => {
  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <Route
          exact
          path={`${APP_PREFIX_PATH}/dashboard`}
          component={lazy(() => import(`./dashboard`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/user`}
          component={lazy(() => import(`./user`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/card`}
          component={lazy(() => import(`./card`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/userreport`}
          component={lazy(() => import(`./reports/user`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/cardreport`}
          component={lazy(() => import(`./reports/card`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/leads`}
          component={lazy(() => import(`./leads`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/orders`}
          component={lazy(() => import(`./orders`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/orders/details/:id`}
          component={lazy(() => import(`../app-views/orders/OrderDetails`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/replacement`}
          component={lazy(() => import(`./orders/Replacement`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/replacement/details/:id`}
          component={lazy(() =>
            import(`../app-views/orders/replaceOrderDetails`)
          )}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/products_category`}
          component={lazy(() => import(`./products`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/productsubcategory`}
          component={lazy(() => import(`./products/productSubCategory`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/organization`}
          component={lazy(() => import(`../app-views/organization/index`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/organization/orgdetails/:id`}
          component={lazy(() => import(`../app-views/organization/OrgDetails`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/venders`}
          component={lazy(() => import(`../app-views/venders/index`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/vendor/vendordetail/:id`}
          component={lazy(() => import(`../app-views/venders/vendorDetails`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/insurance`}
          component={lazy(() => import(`../app-views/insurance/index`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/insurance/insurancedetail`}
          component={lazy(() => import(`../app-views/insurance/insuranceDetail`))}
        />
        <Redirect
          from={`${APP_PREFIX_PATH}`}
          to={`${APP_PREFIX_PATH}/dashboard`}
        />
      </Switch>
    </Suspense>
  );
};

export default React.memo(AppViews);
