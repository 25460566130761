import { GET_ADMIN_BY_ID_SUCCESS, GET_ADMIN_BY_ID_FAILURE } from "../../constants/index"
import { getApi } from "../../../utils/apiCall"


export const getAdminByIdSuccess = (data) => {
    return {
        type: GET_ADMIN_BY_ID_SUCCESS,
        payload: data
    }
}

export const getAdminByIdFailure = (error) => {
    return {
        type: GET_ADMIN_BY_ID_FAILURE,
        payload: error
    }
}

export const GetAdminById = (id) => {
    return async (dispatch, getState) => {
        const { error, result } = await getApi(`admin/detail/${id}`);
        if (!error) {
            return dispatch(getAdminByIdSuccess(result))
        }
        return dispatch(getAdminByIdFailure(error))
    }
}