import { Form, Input, Modal, Upload, message } from 'antd';
import React, { useEffect, useState } from 'react'
import { PlusOutlined } from '@ant-design/icons'
import Flex from 'components/shared-components/Flex';
import "../../../assets/less/styles/custom.css";
import { useDispatch } from 'react-redux';
import { UpdateAdmin } from 'redux/actions/admin/updateAdmin.action';
import { GetAdminById } from 'redux/actions/admin/adminById.action';
import { isValidObject } from 'utils/a1CardUtils';

const NavProfileModal = (props) => {

    const { form, openEditModal, modalToggle, userId, editAdmin, setThumbnail } = props;

    const dispatch = useDispatch()

    const [profile, setProfile] = useState();
    const [userName, setUserName] = useState("");

    

    useEffect(() => {
        if (isValidObject(editAdmin)) { 
            form.setFieldsValue({
                name: editAdmin.name,
            })
         }
    },[editAdmin])

    const onCreate = () => {

        // let user_id = localStorage.getItem("user_id");

        let body = {
            name: userName,
            profile_pic: profile?.fileList[0].thumbUrl,
        }
        dispatch(UpdateAdmin(body, userId)).then((response) => {
            dispatch(GetAdminById(userId));
            modalToggle();
            setThumbnail(body.profile_pic);
            message.success(response.payload.message);

            // window.location.reload();
        })
    }

    const handleChange = ({ fileList }) => {
        setProfile({ fileList })
    }

    const uploadButton = (
        <div>
            <PlusOutlined />
            <div className="ant-upload-text">Upload</div>
        </div>
    );

    const dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
            onSuccess("ok");
        }, 0);
    };



    return (
        <Modal
            visible={openEditModal}
            title="Edit Profile"
            okText="Update"
            cancelText="Cancel"
            onCancel={modalToggle}
            width="400px"
            maskClosable={false}
            onOk={() => {
                form
                    .validateFields()
                    .then(values => {
                        // form.resetFields();
                        onCreate(values);
                    })
                    .catch(info => {
                        console.log('Validate Failed:', info);
                    });
            }}
        >
            <Form
                form={form}
                layout="vertical"
                name="form_in_modal"
                initialValues={{
                    modifier: 'public',
                }}
            >
                <Flex justifyContent="center" >
                    <Form.Item name="profile_img">
                        <div className="clearfix anchor-hide">
                            <Upload
                                accept="image/*"
                                listType="picture-card"
                                customRequest={dummyRequest}
                                onChange={handleChange}
                                openFileDialogOnClick={true}
                                maxCount={1}
                            // onRemove={onRemove}
                            >
                                {uploadButton}
                            </Upload>
                        </div>
                    </Form.Item>
                </Flex>

                <Form.Item
                    name="name"
                    label="Name"
                    rules={[
                        {
                            required: true,
                            message: 'Please enter username!',
                        },
                    ]}
                >
                    <Input onChange={(e) => setUserName(e.target.value)} />
                </Form.Item>
            </Form>
        </Modal>
    )
}


export default NavProfileModal;
