import axios from "axios";
import history from "../history";
import { message as Antdmessage } from "antd";


axios.interceptors.request.use(
    function (config) {
        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);
axios.interceptors.response.use(
    function (response) {

        return response;
    },
    function (error) {
        if ((error.response && error.response.status === 401)) {
            const logout = () => {
                localStorage.removeItem("token");
                localStorage.removeItem("user_id");

                // setTimeout(() => {
                history.push("/auth/login");

                // window.location.reload();
                // }, 5000)

            };
            logout();
        }
        Antdmessage.error(error.response.data.message)

        return Promise.reject(error);
    }
);