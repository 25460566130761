import React, { useEffect, useState } from "react";
import { Menu, Dropdown, Badge, Avatar, List, Button } from "antd";
import { MailOutlined, BellOutlined, WarningOutlined, MessageOutlined, CheckCircleOutlined } from "@ant-design/icons";
import Flex from "components/shared-components/Flex";
import { useDispatch, useSelector } from "react-redux";
import { isValidObject } from "utils/a1CardUtils";
import { AdminNotification, AdminNotificationRead } from "redux/actions/dashboard/notification.action";
import { Link, useHistory } from "react-router-dom";

const getIcon = (icon) => {
  switch (icon) {
    case "alert":
      return <WarningOutlined />;
    case "mail":
      return <MailOutlined />;
    case "check":
      return <CheckCircleOutlined />;
    default:
      return <MessageOutlined style={{ color: "#E05735" }} />;
  }
};


const getNotificationBody = (list, onClickNotify) => {
  return list.length > 0 ? (
    <List
      size="small"
      itemLayout="horizontal"
      dataSource={list}
      renderItem={(item) => (
        <List.Item className="list-clickable" onClick={(e) => onClickNotify(e, item)}>
          <Flex alignItems="center">
            <div className="pr-3">{item.img ? <Avatar src={`/img/avatars/${item.img}`} /> : <Avatar className={`ant-avatar-${item.type}`} icon={getIcon(item.icon)} />}</div>
            <div className="mr-3">
              <span className="font-weight-bold text-dark">{item.customer.first_name + " " + item.customer.last_name} </span>
              <span className="text-gray-light">
                {item.line_items && item.line_items.length > 0 ?
                  ("buy " +
                    item.line_items.reduce((acc, curr) => {
                      return (acc = curr.quantity + acc);
                    }, 0) +
                    " new cards")
                  : "place order for Card replacement"}
              </span>
            </div>
            <small className="ml-auto">{item.created_date.slice(0, 10).split("-").reverse().join("-")}</small>
          </Flex>
        </List.Item>
      )}
    />
  ) : (
    <div className="empty-notification">
      <img src="https://gw.alipayobjects.com/zos/rmsportal/sAuJeJzSKbUmHfBQRzmZ.svg" alt="empty" />
      <p className="mt-3">You have viewed all notifications</p>
    </div>
  );
};

export const NavNotification = () => {
  const [visible, setVisible] = useState(false);
  const [data, setData] = useState([]);
  let history = useHistory();
  const dispatch = useDispatch();

  const getNotificationData = useSelector((state) => state.notificationReducer);


  useEffect(() => {
    const { data } = getNotificationData;
    if (isValidObject(data)) {
      setData(data);
    }
  }, [getNotificationData]);

  // API
  useEffect(() => {
    dispatch(AdminNotification());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClickNotify = (e, values) => {
    let data = { order_id: [values._id] };
    let id = data.order_id[0]
    dispatch(AdminNotificationRead(data)).then((data) => {
      history.push(`/app/orders/details/${id}`);
      window.location.reload();
    })
  };

  const onClickNotifyClear = () => {
    if (data.length > 0) {
      let dataId = { order_id: data.map((item) => item._id) };
      dispatch(AdminNotificationRead(dataId)).then((data) => {
        dispatch(AdminNotification());
        setData([]);
      });
      // .catch((err) => Antdmessage.error(err.message));
    }
  };

  const handleVisibleChange = (flag) => {
    setVisible(flag);
  };

  const notificationList = (
    <div className="nav-dropdown nav-notification">
      <div className="nav-notification-header d-flex justify-content-between align-items-center">
        <h4 className="mb-0">Notification</h4>
        <Button className="text-primary" type="link" onClick={() => onClickNotifyClear()} size="small">
          Clear{" "}
        </Button>
      </div>
      <div className="nav-notification-body">{getNotificationBody(data, onClickNotify)}</div>
      {data.length > 0 ? (
        <div className="nav-notification-footer">
          <Link to='/app/orders/' className="d-block" href="#/">
            View all
          </Link>
        </div>
      ) : null}
    </div>
  );

  return (
    <Dropdown placement="bottomRight" overlay={notificationList} onVisibleChange={handleVisibleChange} visible={visible} trigger={["click"]}>
      <Menu mode="horizontal">
        <Menu.Item key="notification">
          <Badge count={data.length}>
            <BellOutlined className="nav-icon mx-auto" type="bell" />
          </Badge>
        </Menu.Item>
      </Menu>
    </Dropdown>
  );
};

export default NavNotification;
