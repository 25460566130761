import { UPDATE_ADMIN_PASSWORD_SUCCESS, UPDATE_ADMIN_PASSWORD_FAILURE } from "../../constants/index";
import { onSuccess, onFailure } from "../common";

const initStat = { data: [], error: false, errorData: "", isLoading: true };

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initStat, action) => {
    switch (action.type) {
        case UPDATE_ADMIN_PASSWORD_SUCCESS:
            return onSuccess(state, action);
        case UPDATE_ADMIN_PASSWORD_FAILURE:
            return onFailure(state, action);
        default:
            return { ...state };
    }
};
