import axios from "axios";
import Cookies from "js-cookie";
import { setUrl } from "./a1CardUtils";

const headers = {
  Authorization: Cookies.get("token"),
  "x-authorization": Cookies.get("user_id"),
  time: new Date().getTimezoneOffset(),
  Accept: "application/json",
};

export const postApi = async (url, data) => {
  try {
    const result = await axios.post(`${url}`, data, {
      headers: headers,
      baseURL: setUrl(),
    });
    if (result.status === 200) {
      return { error: false, result: result.data };
    }
    return { error: true, result: [] };
  } catch (error) {
    return { error: true, result: [] };
  }
};

export const patchApi = async (url, data) => {
  try {
    const result = await axios.patch(`${url}`, data, {
      headers: headers,
      baseURL: setUrl(),
    });
    if (result.status === 200) {
      return { error: false, result: result.data };
    }
    return { error: true, result: [] };
  } catch (error) {
    return { error: true, result: [] };
  }
};

export const getApi = async (url) => {
  try {
    const result = await axios.get(`${url}`, {
      headers: headers,
      baseURL: setUrl(),
    });
    if (result.status === 200) {
      return { error: false, result: result.data };
    }
    return { error: true, result: [] };
  } catch (error) {
    return { error: true, result: [] };
  }
};

export const deleteApi = async (url) => {
  try {
    const result = await axios.delete(`${url}`, {
      headers: headers,
      baseURL: setUrl(),
    });
    if (result.status === 200) {
      return { error: false, result: result };
    }
    return { error: true, result: [] };
  } catch (error) {
    return { error: true, result: [] };
  }
};
