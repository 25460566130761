import { UPDATE_ADMIN_SUCCESS, UPDATE_ADMIN_FAILURE } from "../../constants/index"
import { patchApi } from "../../../utils/apiCall"

export const updateAdminSuccess = (data) => {
    return {
        type: UPDATE_ADMIN_SUCCESS,
        payload: data
    }
}

export const updateAdminFailure = (error) => {
    return {
        type: UPDATE_ADMIN_FAILURE,
        payload: error
    }
}

export const UpdateAdmin = (data, id) => {
    return async (dispatch, getState) => {
        const { error, result } = await patchApi(`admin/profile/${id}`, data);
        if (!error) {
            return dispatch(updateAdminSuccess(result));
        }
        return dispatch(updateAdminFailure(error));
    }
}