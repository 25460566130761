import { combineReducers } from "redux";
import Auth from "./Auth";
import Theme from "./Theme";
import LoginReducer from "./login/login.reducer";
import LogoutReducer from "./login/logout.reducer";
import DashboardReducer from "./dashboard/dashboard.reducer";
import UserReportReducer from "./report/user.reducer";
import CardReportReducer from "./report/card.reducer";
import TemplatesReducer from "./report/template.reducer";
import CardTypeReducer from "./report/card_type.reducer";
import LeadsReducer from "./leads/leads.reducer";
import CardEditReducer from "./user/card_edit.reducer";
import UserReducer from "./user/user.reducer";
import ProductReducer from "./product/product.reducer";
import PostProductReducer from "./product/postproduct.reducer";
import DeleteProductReducer from "./product/deleteproduct.reducer";
import ProductCategoryListReducer from "./product/product.reducer";
import GetAllCardReducer from "./card/getallcard.reducer";
import CreateCardReducer from "./card/createcard.reducer";
import CardUpdateStatusReducer from "./card/cardstatusupdate.reducer";
import NotificationReducer from "./dashboard/notification.reducer";
import NotificationReadReducer from "./dashboard/notificationRead.reducer";
import OrdersReducer from "./orders/orders.reducer";
import GetOrderByIdReducer from "./orders/orderbyid.reducer";
import OrderStatusUpdateReducer from "./orders/updateorderstatus.reducer";
import AllOrganizationsReducer from "./organizations/getAllOrganizations.reducer";
import OrganizationByIdReducer from "./organizations/orgById.reducer";
import CreateVendorReducer from "./vendor/createVendor.reducer";
import GetVendorReducer from "./vendor/getVendor.reducer";
import GetVendorByIdReducer from "./vendor/getVendorById.reducer";
import UpdateVendorReducer from "./vendor/updateVendor.reducer";
import DeleteVendorReducer from "./vendor/deleteVendor.reducer";
import AssignCardToVendorReducer from "./vendor/assignCard.reducer";
import GetCardByVendorReducer from "./vendor/cardByVendor.reducer";
import CardByDateReducer from './card/cardByDate.reducer';
import UpdateAdminReducer from "./admin/updateAdmin.reducer";
import GetAdminByIdReducer from "./admin/adminById.reducer";
import UpdateAdminPassReducer from "./admin/updatePass.reducer";
import GetInsuranceReducer from "./insurance/getInsurance.reducer";
import InsuranceByEmail from "./insurance/insuranceByEmail.reducer";
import DeleteCardProfiles from "./card/deleteCardProfiles.reducer";
import GetCardByOrderIdReducer from "./orders/getCardByOrderId.reducer";
import GetSubProductCategory from "./subProduct/subProductCategory.reducer";
import AddSubProductCategory from "./subProduct/subProductCategory.reducer";
import DeleteSubProductCategory from "./subProduct/deleteProduct.reducer";
import UpdateSubProductCategory from "./subProduct/updateProduct.reducer";
import DeleteVendorCardReducer from "./vendor/deleteVendorCard.reducer";
import DeleteUserReducer from "./user/deleteUser.reducer";
import DeleteCard from "./card/deleteCard.reducer";
import AssignCardToOrderReducer from "./orders/assignCardToOrder.reducer";
import DeleteOrderReducer from "./orders/deleteOrder.reducer";
import DeleteOrgReducer from "./organizations/deleteOrg.reducer";
import DeleteInsuranceReducer from "./insurance/deleteInsurance.reducer";
import DeleteLeadReducer from "./leads/deleteLead.reducer";

const reducers = combineReducers({
  theme: Theme,
  auth: Auth,
  loginReducer: LoginReducer,
  logoutReducer: LogoutReducer,
  dashboardReducer: DashboardReducer,
  userReportReducer: UserReportReducer,
  cardReportReducer: CardReportReducer,
  templateReducer: TemplatesReducer,
  cardTypeReducer: CardTypeReducer,
  leadsReducer: LeadsReducer,
  cardEditReducer: CardEditReducer,
  userReducer: UserReducer,
  getProductReducer: ProductReducer,
  postProductReducer: PostProductReducer,
  deleteProductReducer: DeleteProductReducer,
  productCategoryListReducer: ProductCategoryListReducer,
  getAllCardReducer: GetAllCardReducer,
  createCardreducer: CreateCardReducer,
  cardUpdateStatusReducer: CardUpdateStatusReducer,
  notificationReducer: NotificationReducer,
  notificationReadReducer: NotificationReadReducer,
  ordersReducer: OrdersReducer,
  getOrderByIdReducer: GetOrderByIdReducer,
  orderStatusUpdateReducer: OrderStatusUpdateReducer,
  allOrganizationsReducer: AllOrganizationsReducer,
  organizationByIdReducer: OrganizationByIdReducer,
  createVendorReducer: CreateVendorReducer,
  getVendorReducer: GetVendorReducer,
  getVendorByIdReducer: GetVendorByIdReducer,
  updateVendorReducer: UpdateVendorReducer,
  deleteVendorReducer: DeleteVendorReducer,
  assignCardToVendorReducer: AssignCardToVendorReducer,
  getCardByVendorReducer: GetCardByVendorReducer,
  cardByDateReducer: CardByDateReducer,
  updateAdminReducer: UpdateAdminReducer, 
  getAdminByIdReducer: GetAdminByIdReducer,
  updateAdminPassReducer: UpdateAdminPassReducer,
  getInsuranceReducer: GetInsuranceReducer,
  insuranceByEmail: InsuranceByEmail,
  deleteCardProfiles: DeleteCardProfiles,
  getCardByOrderIdReducer: GetCardByOrderIdReducer,
  getSubProductCategory: GetSubProductCategory,
  addSubProductCategory: AddSubProductCategory,
  deleteSubProductCategory: DeleteSubProductCategory,
  updateSubProductCategory: UpdateSubProductCategory,
  deleteVendorCardReducer: DeleteVendorCardReducer,
  deleteUserReducer: DeleteUserReducer,
  deleteCard: DeleteCard,
  assignCardToOrderReducer: AssignCardToOrderReducer,
  deleteOrderReducer: DeleteOrderReducer,
  deleteOrgReducer: DeleteOrgReducer,
  deleteInsuranceReducer: DeleteInsuranceReducer,
  deleteLeadReducer: DeleteLeadReducer
});

export default reducers;
