import { GET_ADMIN_BY_ID_SUCCESS, GET_ADMIN_BY_ID_FAILURE } from "../../constants/index";
import { onSuccess, onFailure } from "../common";

const initStat = { data: [], error: false, errorData: "", isLoading: true };

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initStat, action) => {
    switch (action.type) {
        case GET_ADMIN_BY_ID_SUCCESS:
            return onSuccess(state, action);
        case GET_ADMIN_BY_ID_FAILURE:
            return onFailure(state, action);
        default:
            return { ...state };
    }
};
