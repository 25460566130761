import { GET_PRODUCT_CATEGORY_LIST_SUCCESS, GET_PRODUCT_CATEGORY_LIST_FAILURE} from "../../constants/index"
import { onSuccess, onFailure } from "../common";

const initStat = { data: [], error: false, errorData: "", isLoading: true };

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initStat, action) => {
  switch (action.type) {
    case GET_PRODUCT_CATEGORY_LIST_SUCCESS:
      return onSuccess(state, action);
    case GET_PRODUCT_CATEGORY_LIST_FAILURE:
      return onFailure(state, action);
    default:
      return { ...state };
  }
};