import { GET_VENDOR_BY_ID_SUCCESS, GET_VENDOR_BY_ID_FAILURE } from '../../constants/index'
import { onSuccess, onFailure } from '../common'

const initStat = { data: [], error: false, errorData: "", isLoading: true };

export default (state = initStat, action) => {
    switch (action.type) {
        case GET_VENDOR_BY_ID_SUCCESS:
            return onSuccess(state, action);
        case GET_VENDOR_BY_ID_FAILURE:
            return onFailure(state, action);
        default:
            return { ...state }
    }
}