export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAILURE = "USER_LOGIN_FAILURE";

export const USER_LOGOUT_SUCCESS = "USER_LOGOUT_SUCCESS";
export const USER_LOGOUT_FAILURE = "USER_LOGOUT_FAILURE";

export const USER_LIST_SUCCESS = "USER_LIST_SUCCESS";
export const USER_LIST_FAILURE = "USER_LIST_FAILURE";

export const ADMIN_DASHBAORD_SUCCESS = "ADMIN_DASHBAORD_SUCCESS";
export const ADMIN_DASHBAORD_FAILURE = "ADMIN_DASHBAORD_FAILURE";

export const GET_ORDER_SUCCESS = "GET_ORDER_SUCCESS";
export const GET_ORDER_FAILURE = "GET_ORDER_FAILURE";

export const GET_ORDER_BY_ID_SUCCESS = "GET_ORDER_BY_ID_SUCCESS";
export const GET_ORDER_BY_ID_FAILURE = "GET_ORDER_BY_ID_FAILURE";

export const ORDER_STATUS_UPDATE_SUCCESS = "ORDER_STATUS_UPDATE_SUCCESS";
export const ORDER_STATUS_UPDATE_FAILURE = "ORDER_STATUS_UPDATE_FAILURE";

export const ADMIN_NOTIFICATION_SUCCESS = "ADMIN_NOTIFICATION_SUCCESS";
export const ADMIN_NOTIFICATION_FAILURE = "ADMIN_NOTIFICATION_FAILURE";

export const ADMIN_NOTIFICATION_READ_SUCCESS = "ADMIN_NOTIFICATION_READ_SUCCESS";
export const ADMIN_NOTIFICATION_READ_FAILURE = "ADMIN_NOTIFICATION_READ_FAILURE";

export const USER_REPORT_SUCCESS = "USER_REPORT_SUCCESS";
export const USER_REPORT_FAILURE = "USER_REPORT_FAILURE";

export const CARD_REPORT_SUCCESS = "CARD_REPORT_SUCCESS";
export const CARD_REPORT_FAILURE = "CARD_REPORT_FAILURE";

export const CARD_EDIT_SUCCESS = "CARD_EDIT_SUCCESS";
export const CARD_EDIT_FAILURE = "CARD_EDIT_FAILURE";

export const GET_TEMPLATES_SUCCESS = "GET_TEMPLATES_SUCCESS";
export const GET_TEMPLATES_FAILURE = "GET_TEMPLATES_FAILURE";

export const GET_CARD_TYPE_SUCCESS = "GET_CARD_TYPE_SUCCESS";
export const GET_CARD_TYPE_FAILURE = "GET_CARD_TYPE_FAILURE";

export const LEADS_SUCCESS = "LEADS_SUCCESS";
export const LEADS_FAILURE = "LEADS_FAILURE";

export const PRODUCT_LIST_SUCCESS = "PRODUCT_SUCCESS";
export const PRODUCT_LIST_FAILURE = "PRODUCT_FAILURE";

export const POST_CATEGORY_LIST_SUCCESS = "POST_CATEGORY_LIST_SUCCESS";
export const POST_CATEGORY_LIST_FAILURE = "POST_CATEGORY_LIST_FAILURE";

export const DELETE_PRODUCT_CATEGORY_SUCCESS = "DELETE_PRODUCT_CATEGORY_SUCCESS";
export const DELETE_PRODUCT_CATEGORY_FAILURE = "DELETE_PRODUCT_CATEGORY_FAILURE";

export const GET_PRODUCT_CATEGORY_SUCCESS = "GET_PRODUCT_CATEGORY_SUCCESS";
export const GET_PRODUCT_CATEGORY_FAILURE = "GET_PRODUCT_CATEGORY_FAILURE";

export const ALL_CARD_SUCCESS = "ALL_CARD_SUCCESS";
export const ALL_CARD_FAILURE = "ALL_CARD_FAILURE";

export const CREATE_CARD_SUCCESS = "CREATE_CARD_SUCCESS";
export const CREATE_CARD_FAILURE = "CREATE_CARD_FAILURE";

export const CARD_UPDATE_STATUS_SUCCESS = "CARD_UPDATE_STATUS_SUCCESS";
export const CARD_UPDATE_STATUS_FAILURE = "CARD_UPDATE_STATUS_FAILURE";

export const UPDATE_PRODUCT_CATEGORY_SUCCESS = "UPDATE_PRODUCT_CATEGORY_SUCCESS";
export const UPDATE_PRODUCT_CATEGORY_FAILURE = "UPDATE_PRODUCT_CATEGORY_FAILURE"

export const CHECK_VALID_TOKEN_SUCCESS = "CHECK_VALID_TOKEN_SUCCESS";
export const CHECK_VALID_TOKEN_FAILURE = "CHECK_VALID_TOKEN_FAILURE";

export const GET_ALL_ORGANIZATIONS_SUCCESS = "GET_ALL_ORGANIZATIONS_SUCCESS"
export const GET_ALL_ORGANIZATIONS_FAILURE = "GET_ALL_ORGANIZATIONS_FAILURE";

export const GET_ORGANIZATION_BY_ID_SUCCESS = "GET_ORGANIZATION_BY_ID_SUCCESS";
export const GET_ORGANIZATION_BY_ID_FAILURE = "GET_ORGANIZATION_BY_ID_FAILURE";

export const CREATE_VENDOR_SUCCESS = "CREATE_VENDOR_SUCCESS";
export const CREATE_VENDOR_FAILURE = "CREATE_VENDOR_FAILURE";

export const GET_VENDOR_SUCCESS = 'GET_VENDOR_SUCCESS';
export const GET_VENDOR_FAILURE = 'GET_VENDOR_FAILURE';

export const GET_VENDOR_BY_ID_SUCCESS = "GET_VENDOR_BY_ID_SUCCESS";
export const GET_VENDOR_BY_ID_FAILURE = "GET_VENDOR_BY_ID_FAILURE";

export const UPDATE_VENDOR_SUCCESS = "UPDATE_VENDOR_SUCCESS";
export const UPDATE_VENDOR_FAILURE = "UPDATE_VENDOR_FAILURE";

export const DELETE_VENDOR_SUCCESS = "DELETE_VENDOR_SUCCESS";
export const DELETE_VENDOR_FAILURE = "DELETE_VENDOR_FAILURE";

export const ASSIGN_CARD_TO_VENDOR_SUCCESS = "ASSIGN_CARD_TO_VENDOR_SUCCESS";
export const ASSIGN_CARD_TO_VENDOR_FAILURE = "ASSIGN_CARD_TO_VENDOR_FAILURE";

export const GET_CARD_BY_VENDOR_SUCCESS = "GET_CARD_BY_VENDOR_SUCCESS";
export const GET_CARD_BY_VENDOR_FAILURE = "GET_CARD_BY_VENDOR_FAILURE";

export const CARD_BY_DATE_SUCCESS = "CARD_BY_DATE_SUCCESS";
export const CARD_BY_DATE_FAILURE = "CARD_BY_DATE_FAILURE";

export const UPDATE_ADMIN_SUCCESS = "UPDATE_ADMIN_SUCCESS";
export const UPDATE_ADMIN_FAILURE = "UPDATE_ADMIN_FAILURE";

export const GET_ADMIN_BY_ID_SUCCESS = "GET_ADMIN_BY_ID_SUCCESS";
export const GET_ADMIN_BY_ID_FAILURE = "GET_ADMIN_BY_ID_FAILURE";

export const UPDATE_ADMIN_PASSWORD_SUCCESS = "UPDATE_ADMIN_PASSWORD_SUCCESS";
export const UPDATE_ADMIN_PASSWORD_FAILURE = "UPDATE_ADMIN_PASSWORD_FAILURE";


export const GET_INSURANCE_SUCCESS = "GET_INSURANCE_SUCCESS";
export const GET_INSURANCE_FAILURE = "GET_INSURANCE_FAILURE";

export const GET_INSURANCE_BY_EMAIL_SUCCESS = "GET_INSURANCE_BY_EMAIL_SUCCESS";
export const GET_INSURANCE_BY_EMAIL_FAILURE = "GET_INSURANCE_BY_EMAIL_FAILURE";

export const DELETE_CARD_PROFILES_SUCCESS = "DELETE_CARD_PROFILES_SUCCESS";
export const DELETE_CARD_PROFILES_FAILURE = "DELETE_CARD_PROFILES_FAILURE";

export const GET_CARD_BY_ORDER_ID_SUCCESS = "GET_CARD_BY_ORDER_ID_SUCCESS";
export const GET_CARD_BY_ORDER_ID_FAILURE = "GET_CARD_BY_ORDER_ID_FAILURE";

export const GET_PRODUCT_CATEGORY_LIST_SUCCESS = "GET_PRODUCT_CATEGORY_LIST_SUCCESS";
export const GET_PRODUCT_CATEGORY_LIST_FAILURE = "GET_PRODUCT_CATEGORY_LIST_FAILURE";

export const POST_SUB_PRODUCT_CATEGORY_SUCCESS = "POST_SUB_PRODUCT_CATEGORY_SUCCESS";
export const POST_SUB_PRODUCT_CATEGORY_FAILURE = "POST_SUB_PRODUCT_CATEGORY_FAILURE";

export const DELETE_SUB_PRODUCT_CATEGORY_SUCCESS = "DELETE_SUB_PRODUCT_CATEGORY_SUCCESS";
export const DELETE_SUB_PRODUCT_CATEGORY_FAILURE = "DELETE_SUB_PRODUCT_CATEGORY_FAILURE";

export const UPDATE_SUB_PRODUCT_CATEGORY_SUCCESS = "UPDATE_SUB_PRODUCT_CATEGORY_SUCCESS";
export const UPDATE_SUB_PRODUCT_CATEGORY_FAILURE = "UPDATE_SUB_PRODUCT_CATEGORY_FAILURE";

export const DELETE_VENDOR_CARD_SUCCESS = "DELETE_VENDOR_CARD_SUCCESS";
export const DELETE_VENDOR_CARD_FAILURE = "DELETE_VENDOR_CARD_FAILURE";

export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAILURE = "DELETE_USER_FAILURE";

export const DELETE_CARD_SUCCESS = "DELETE_CARD_SUCCESS";
export const DELETE_CARD_FAILURE = "DELETE_CARD_FAILURE";

export const ASSIGN_CARD_TO_ORDER_SUCCESS = "ASSIGN_CARD_TO_ORDER_SUCCESS";
export const ASSIGN_CARD_TO_ORDER_FAILURE = "ASSIGN_CARD_TO_ORDER_FAILURE";

export const DELETE_ORDER_SUCCESS = "DELETE_ORDER_SUCCESS";
export const DELETE_ORDER_FAILURE = "DELETE_ORDER_FAILURE";

export const DELETE_ORGANIZATION_SUCCESS = "DELETE_ORGANIZATION_SUCCESS";
export const DELETE_ORGANIZATION_FAILURE = "DELETE_ORGANIZATION_FAILURE";

export const DELETE_INSURANCE_SUCCESS = "DELETE_INSURANCE_SUCCESS";
export const DELETE_INSURANCE_FAILURE = "DELETE_INSURANCE_FAILURE";

export const DELETE_LEAD_SUCCESS = "DELETE_LEAD_SUCCESS";
export const DELETE_LEAD_FAILURE = "DELETE_LEAD_FAILURE";