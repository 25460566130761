import { UPDATE_ADMIN_PASSWORD_SUCCESS, UPDATE_ADMIN_PASSWORD_FAILURE } from "../../constants/index"
import { patchApi } from "../../../utils/apiCall"

export const updateAdminPassSuccess = (data) => {
    return {
        type: UPDATE_ADMIN_PASSWORD_SUCCESS,
        payload: data
    }
}

export const updateAdminPassFailure = (error) => {
    return {
        type: UPDATE_ADMIN_PASSWORD_FAILURE,
        payload: error
    }
}

export const UpdateAdminPassword = (data) => {
    return async (dispatch, getState) => {
        const { error, result } = await patchApi(`admin/password`, data);
        if (!error) {
            return dispatch(updateAdminPassSuccess(result));
        }
        return dispatch(updateAdminPassFailure(error));
    }
}