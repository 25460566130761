import { Form, Input, Modal, message } from 'antd';
import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { UpdateAdminPassword } from 'redux/actions/admin/updatePass.action';

const ChangePassModal = ({ changePassModalToggle, openChangePasswordModal, form,userId }) => {

    const [password, setPassword] = useState();
    const [nPassword, setNPassword] = useState();
    const [cPassword, setCPassword] = useState();

    const dispatch = useDispatch();
    
    const onCreate = () => {
        if (nPassword === cPassword) {
            let body = {
                current_password: password,
                password: nPassword,
                cpassword: cPassword,
                userId: userId,
            }
            dispatch(UpdateAdminPassword(body)).then((response) => {
                message.success(response.payload.message);
                changePassModalToggle();
            })
        } else {
            message.error("Both password should be same");
    }
    }

    return (
        <Modal
            visible={openChangePasswordModal}
            title="Change Password"
            okText="Change"
            cancelText="Cancel"
            onCancel={changePassModalToggle}
            width="400px"
            maskClosable={false}
            onOk={() => {
                form
                    .validateFields()
                    .then(values => {
                        // form.resetFields();
                        onCreate(values);
                    })
                    .catch(info => {
                        console.log('Validate Failed:', info);
                    });
            }}
        >
            <Form
                form={form}
                layout="vertical"
                name="form_in_modal"
                initialValues={{
                    modifier: 'public',
                }}
            >
                <Form.Item
                    name="current_password"
                    label="Current Password"
                    rules={[
                        {
                            pattern: new RegExp(/^\S+$/),
                            required: true,
                            message: 'Enter password without Space!',
                        },
                    ]}
                >
                    <Input.Password onChange={ (e) => setPassword(e.target.value)} placeholder="Enter current password"/>
                </Form.Item>

                <Form.Item
                    name="password"
                    label="New Password"
                    rules={[
                        {
                            pattern: new RegExp(/^\S+$/),
                            required: true,
                            message: 'Please enter new password!',
                        },
                    ]}
                >
                    <Input.Password onChange={ (e) => setNPassword(e.target.value)}  placeholder="Enter new password"/>
                </Form.Item>

                <Form.Item
                    name="cpassword"
                    label="Confirm New Password"
                    rules={[
                        {
                            pattern: new RegExp(/^\S+$/),
                            required: true,
                            message: 'Please enter new password again to confirm!',
                        },
                    ]}
                >
                    <Input.Password onChange={ (e) => setCPassword(e.target.value)} placeholder="Enter new password to confirm"/>
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default ChangePassModal
