import { ADMIN_NOTIFICATION_SUCCESS, ADMIN_NOTIFICATION_FAILURE, ADMIN_NOTIFICATION_READ_SUCCESS, ADMIN_NOTIFICATION_READ_FAILURE } from "../../constants/index";
import { getApi, patchApi } from "../../../utils/apiCall";

export const notificationSuccess = (data) => {
  return {
    type: ADMIN_NOTIFICATION_SUCCESS,
    payload: data,
  };
};

export const notificationFailure = (error) => {
  return {
    type: ADMIN_NOTIFICATION_FAILURE,
    payload: error,
  };
};

export const AdminNotification = () => {
  return async (dispatch, getState) => {
    const { error, result } = await getApi(`admin/notify_order`);
    if (!error) {
      return dispatch(notificationSuccess(result));
    }
    return dispatch(notificationFailure(error));
  };
};

export const notificationReadSuccess = (data) => {
  return {
    type: ADMIN_NOTIFICATION_READ_SUCCESS,
    payload: data,
  };
};

export const notificationReadFailure = (error) => {
  return {
    type: ADMIN_NOTIFICATION_READ_FAILURE,
    payload: error,
  };
};

export const AdminNotificationRead = (order_id) => {
  return async (dispatch, getState) => {
    const { error, result } = await patchApi(`admin/notify_order`, order_id);
    if (!error) {
      return dispatch(notificationSuccess(result));
    }
    return dispatch(notificationFailure(error));
  };
};
